$(document).ready(function() {
  //определение ширины скролла для фансибокс
  // создадим элемент с прокруткой
  var div = document.createElement('div');
  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

// при display:none размеры нельзя узнать
// нужно, чтобы элемент был видим,
// visibility:hidden - можно, т.к. сохраняет геометрию
  div.style.visibility = 'hidden';
  document.body.appendChild(div);
  var scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  console.log(scrollWidth);

  //body

  if($('[data-fancybox]').length>0){
    $('[data-fancybox="images"]').fancybox({
      thumbs : {
        autoStart : true
      },
      buttons : [
        'zoom',
        'close'
      ]
    });
  }


  $("body").each(function () {
    if ($("body").hasClass("page-services")){
      $("body").css("padding-right",'0');
    } else {
      $("body").css("padding-right",scrollWidth);
    }
  });
  //переход по страницам
  if(window.location.href.indexOf('vr-solutions') > -1){
    setTimeout(function(){
      //$("#container").remove();

      $('.main-services').addClass('active');
    }, 300);
    setTimeout(function () {
      $("header").addClass("header-menu");


      if ($(window).width() <= 991) {
        $('.header-menu .menu-toggle').click(function() {
          $(this).parent().find('ul').toggleClass('open');
          $(this).closest('.menu-box').toggleClass('active');
        });
      }

    },100);

  }

  if(window.location.href.indexOf('ar-applications.html') > -1){
    setTimeout(function(){
      //$("#container").remove();

      $('.main-services').addClass('active');
    }, 300);
    setTimeout(function () {
      $("header").addClass("header-menu");


      if ($(window).width() <= 991) {
        $('.header-menu .menu-toggle').click(function() {
          $(this).parent().find('ul').toggleClass('open');
          $(this).closest('.menu-box').toggleClass('active');
        });
      }

    },100);

  }
  if(window.location.href.indexOf('3d-rendering.html') > -1){
    setTimeout(function(){
      //$("#container").remove();

      $('.main-services').addClass('active');
    }, 300);
    setTimeout(function () {
      $("header").addClass("header-menu");


      if ($(window).width() <= 991) {
        $('.header-menu .menu-toggle').click(function() {
          $(this).parent().find('ul').toggleClass('open');
          $(this).closest('.menu-box').toggleClass('active');
        });
      }

    },100);

  }
  if(window.location.href.indexOf('web.html') > -1){
    setTimeout(function(){
      //$("#container").remove();

      $('.main-services').addClass('active');
    }, 300);
    setTimeout(function () {
      $("header").addClass("header-menu");


      if ($(window).width() <= 991) {
        $('.header-menu .menu-toggle').click(function() {
          $(this).parent().find('ul').toggleClass('open');
          $(this).closest('.menu-box').toggleClass('active');
        });
      }

    },100);

  }
  if(window.location.href.indexOf('about.html') > -1){
    setTimeout(function(){
      //$("#container").remove();

      $('.main-services').addClass('active');
    }, 300);
    setTimeout(function () {
      $("header").addClass("header-menu");


      if ($(window).width() <= 991) {
        $('.header-menu .menu-toggle').click(function() {
          $(this).parent().find('ul').toggleClass('open');
          $(this).closest('.menu-box').toggleClass('active');
        });
      }

    },100);

  }
  if ($(window).width() <= 991) {
    $('.header-menu .menu-toggle').click(function() {
      $(this).parent().find('ul').toggleClass('open');
      $(this).closest('.menu-box').toggleClass('active');
    });
    if($(".about-step").length>0){
      $(".about-step__item").each(function () {
        $(this).find(".about-step__title").click(function () {
          $(".about-step__item").removeClass("active");
          $(this).parent(".about-step__item").addClass("active");
        });
      });
    }
  };
  if ($(window).width() >= 992) {
    $(".menu-left__catalog-menu >ul>li>ul>li").each(function () {
      $(this).mouseover(function () {
        $(".menu-left__catalog-menu  >ul>li>ul>li").removeClass("active");
        $(this).addClass("active");
      });
    });
    $(".about-step__item").each(function () {
      $(this).mouseover(function () {
        $(".about-step__item").removeClass("active");
        $(this).addClass("active");
      });
    });
  }
  /* $(".home-sl__item").click(function (e) {
     e.preventDefault();
     //$(this).closest(".swiper-wrapper").find(".home-sl__item:first-child").addClass("test");
     $(this).closest(".swiper-wrapper").find(".swiper-slide:first-child").find('.home-sl__item').addClass("test");
     $(this).closest(".page-home").addClass("sd-active").css("padding-right",'0');
     //$(this).addClass("test");
     $(this).closest(".pjax-container").find(".main-vr").addClass("active").find(".footer-main-home").show();
     $(this).closest(".pjax-container").find(".footer-main-home").addClass("nt");
   });*/


  /*if ($(window).width() >= 992) {
    //animation переход
    $(".swiper-slide a").on('click', function(e) {
      e.preventDefault();
      var url = this.href;
      $(this).closest(".swiper-wrapper").find(".swiper-slide:first-child").find('.home-sl__item').addClass("test");
      $(this).closest(".page-home").addClass("ms-active ");
      $(this).closest(".page-home").addClass("page-services").css("padding-right",'0');
      $(".main-services-copy").addClass("active");

      setTimeout(function(){
        //$("#container").remove();
        $('.page-home').find(".home-sl .swiper-wrapper").addClass('none');
      }, 100);
      setTimeout(function(){
        //$("#container").remove();
        $("#content").load(url + '#content').hide().fadeIn('slow');

      }, 300);

    })



    $(".page-home .services-header__back").click(function () {
      $(this).closest(".pjax-container").find(".home-sl__item").removeClass("test");
      $(this).closest(".page-home").removeClass("ms-active").css("padding-right",scrollWidth);
      $(this).closest(".page-home").removeClass("page-services").css("padding-right",scrollWidth);
      $(this).closest(".main-services-copy").removeClass("active");
      setTimeout(function(){
        //$("#container").remove();
        $('.page-home').find(".home-sl .swiper-wrapper").removeClass('none');
        $("#content").hide().fadeOut('slow');
      }, 200);


    });
  }*/


  //fix header fon
  $(window).on('resize', function() {
    if ($(window).width() >= 1200) {
      console.log("1200");
      $(function () {
        $(window).scroll(function () {
          var top = $(document).scrollTop();
          var header = $(".main-services .services-header").height() + 72;
          /*console.log(hheader2)*/
          if (top < header) {
            $(".main-services-header").removeClass("fix")
            $(".header").removeClass("fix");
          }


          else {
            $(".main-services-header").addClass("fix");
            $(".header").addClass("fix");
          }
        });
      });
    }

    else{

      $(function () {
        $(window).scroll(function () {
          $(".main-services-header").removeClass("fix")
          $(".header").removeClass("fix");

        });
      });

      $(".main-services-header").removeClass("fix")
      $(".header").removeClass("fix");
      console.log("1100");
    }

  });
  if ($(window).width() >= 1200) {
    $(function () {
      $(window).scroll(function () {
        var top = $(document).scrollTop();
        var header = $(".main-services .services-header").height() + 72;
        /*console.log(hheader2)*/
        if (top < header) {
          $(".main-services-header").removeClass("fix")
          $(".header").removeClass("fix");
        }


        else {
          $(".main-services-header").addClass("fix");
          $(".header").addClass("fix");
        }
      });
    });
  }

  //scroll pc
  /*if ($(window).width() >= 1200) {
    window.addEventListener('scroll', scrollChange);
    var icon = document.querySelector('.icon');
    /!* var title = document.querySelector('.title');*!/


    function scrollChange() {

      if ( document.documentElement.scrollTop > 40 || window.pageYOffset > 40 ) {
        $(".page-services .header").addClass("fix");
        icon.style.display = 'none';
        /!* title.style.marginLeft = '155px';
         title.style.transition = 'all .3s';*!/

      }

      if ( document.documentElement.scrollTop < 1 || window.pageYOffset < 1 ){
        $(".page-services .header").removeClass("fix");
        icon.style.display = 'block';
        /!*title.style.marginLeft = '0px';*!/

      }
    };
  }*/


  $("#content").find('.main-services').addClass('active');
  $('video:not(".gif")').mediaelementplayer({
    alwaysShowControls: false,
    videoVolume: 'horizontal',
    features: ['playpause','progress','current','duration','tracks','volume','fullscreen']
  });

  //menu pc
  if ($(window).width() >= 992) {
    $(".menu-toggle").click(function () {
      console.log("click");

      if( $(".menu-toggle").closest(".menu-box").hasClass("menu-active")){
        $(this).closest(".menu-box").removeClass("menu-active");
        setTimeout(function () {
          $(".menu-toggle").closest(".menu-box").find(".menu-list li").removeClass("active");
        }, 100);

      }
      else{
        $(this).closest(".menu-box").addClass("menu-active");
        setTimeout(function () {
          $(".menu-toggle").closest(".menu-box").find(".menu-list li").addClass("active");
        }, 100);
      }
    });
  }


  if ($(window).width() <= 991) {


   /* $('.header-menu .menu-btn').click(function() {
      console.log("2222")
      //e.preventDefault();
      $(this).addClass("5555");
      $(this).parent().find('ul').toggleClass('open');
      $(this).closest('.menu-box').toggleClass('active');
    });*/

  }
  //menu mobile


  //menu left
  $(function(){
    $(".menu-left").each(function () {

      $('.menu-left .scroll-to').click(function(){
        $(".menu-left a").removeClass("active");
        $(".menu-left a").removeClass("active1");
        $(this).removeClass("no-active");
        $(".menu-left .scroll-to2").addClass("no-active");
        $(this).addClass("active");

        var target = $(this).attr('href');
        $('html, body').animate({scrollTop: $(target).offset().top - 200}, 100);
        return false;
      });
    })

  });
  $(function(){

    $('.menu-left .scroll-to2').click(function(){
      $(this).removeClass("no-active");
      setTimeout(function () {
        $(".menu-left .scroll-to").addClass("no-active");
      },100);

      $(this).addClass("active1");

      var target2 = $(this).attr('href');
      $('html, body').animate({scrollTop: $(target2).offset().top - 200}, 100);
      return false;
    });
  });
  jQuery(window).scroll(function(){
    var $sections = $('section');
    $sections.each(function(i,el){
      var top  = $(el).offset().top - 400;
      //var bottom = top +$(el).height() ;
      var bottom = top +$(el).height() ;
      var scroll = $(window).scrollTop();
      var id = $(el).attr('id');
      if( scroll > top && scroll < bottom){
        $('a.active').removeClass('active no-active');
        $('a.active1').removeClass('active1');
        $('a[href="#'+id+'"]').addClass('active');

      }
    })
  });
  //menu left and



  //base
  if ($("#tabs").length > 0) {
    $(function () {
      $('#tabs').tabs({
      });
      $('.ui-tabs-tab').click(function () {
        $(".catalog-sl .row").slick('slickSetOption', 'adaptiveHeight', true, true);
        $('.about-expert__items .row').slick('slickSetOption', 'adaptiveHeight', true, true);
        $('video').each(function() {
          this.pause();
        });

      });

    });
  }



  //home slider
  var swiperHomeSl = new Swiper('.home-sl .swiper-container', {
    lazyLoading: true,
    direction: 'horizontal',
    loopAdditionalSlides:1,
    mousewheel: {
      invert: false,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },

    loop: true,
    speed: 1000,
    slidesPerView: 4,
    spaceBetween: 30,

    pagination: {
      el: '.swiper-pagination-reviews',
    },
    navigation: {
      nextEl: '.swiper-button-next-home',
      prevEl: '.swiper-button-prev-home',
    },
    breakpoints: {

      560: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1199: {
        slidesPerView: 3,
        spaceBetween: 40
      },


    }


  });
  $('.swiper-slide-next').each(function () {
    $('.swiper-slide-next').on('mouseover', function () {


    });
  });
/*  jQuery('.swiper-slide-next').hover(function(){
    jQuery('.swiper-button-next-home').trigger('click');
  });*/
  var homeslw = $(".home-sl").width();
  var vindw = $(window).width();

  var othersww = vindw - homeslw;
  var othersww2 = othersww/2;
  console.log(othersww2);

  $(".home-sl-next").width(othersww2).css("right", -othersww2);

  $(".home-sl-next").on("mouseover", function () {
    jQuery('.swiper-button-next-home').trigger('click');

  });
  $(".home-sl-next").click(function () {
    jQuery('.swiper-button-next-home').trigger('click');

  });
  $(".home-sl-prev").width(othersww2).css("left", -othersww2);
  $(".home-sl-prev").on("mouseover", function () {
    jQuery('.swiper-button-prev-home').trigger('click');


  });
  $(".home-sl-prev").click(function () {
    jQuery('.swiper-button-prev-home').trigger('click');

    });

  $('.home-sl__item').each(function () {
    $(".home-sl__item").on("mouseover", function () {

      $(".home-sl .swiper-slide").removeClass("swiper-slide-active");
      //$(".home-sl .swiper-slide").next().removeClass("swiper-slide-next");
      $(this).parent().addClass("swiper-slide-active");


     /* if($(this).parent().hasClass("swiper-slide-prev")){
        //$(".home-sl .swiper-slide").next().removeClass("swiper-slide-next");
        /!*$(this).next().addClass("swiper-slide-next");
        $(this).prev().addClass("swiper-slide-prev");
        $(this).removeClass("swiper-slide-prev");*!/

        setTimeout(function () {
          jQuery('.swiper-button-prev-home').trigger('click');
        }, 3000)


      }
      else if($(this).parent().hasClass("swiper-slide-next")){
        setTimeout(function () {
          jQuery('.swiper-button-next-home').trigger('click');
        }, 3000)

      }
      else{
        setTimeout(function () {
          jQuery('.swiper-button-next-home').trigger('click');
        }, 500)

      }*/

    });



  });


  if($(".area-logo").length>0){
    $(window).on('load', function () {
      var $preloader = $('.area'),
        $spinner   = $preloader.find('.area-logo');
      $spinner.fadeOut();
      $preloader.delay(800).fadeOut('slow');
    });
  }


  //services slider
  var galleryThumbs = new Swiper('.gallery-thumbs1', {
    spaceBetween: 10,
    slidesPerView: 4,

    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {

      560: {
        slidesPerView: 2,

      },
      767: {
        slidesPerView: 3,

      },
      991: {
        slidesPerView: 4,

      },
      1199: {
        slidesPerView: 4,

      },


    }
  });
  var galleryTop = new Swiper('.gallery-top1', {
    spaceBetween: 0,
    touchRatio:0,

    touchStartPreventDefault:false,
    thumbs: {
      swiper: galleryThumbs,
    },
  });
  galleryTop.on('slideChange', function() {

    $('.swiper-slide').find('video').each(function() {
      this.pause();
    });

  });

  var galleryThumbs2 = new Swiper('.gallery-thumbs2', {
    spaceBetween: 10,
    slidesPerView: 4,

    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,

    breakpoints: {

      560: {
        slidesPerView: 2,

      },
      767: {
        slidesPerView: 3,

      },
      991: {
        slidesPerView: 4,

      },
      1199: {
        slidesPerView: 4,

      },


    }
  });
  var galleryTop2 = new Swiper('.gallery-top2', {
    spaceBetween: 0,
    touchRatio:0,

    touchStartPreventDefault:false,
    thumbs: {
      swiper: galleryThumbs2,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  galleryTop2.on('slideChange', function() {

    $('.swiper-slide').find('video').each(function() {
      this.pause();
    });

  });

  var galleryThumbs4 = new Swiper('.gallery-thumbs4', {
    spaceBetween: 10,
    slidesPerView: 4,

    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,

    breakpoints: {

      560: {
        slidesPerView: 2,

      },
      767: {
        slidesPerView: 3,

      },
      991: {
        slidesPerView: 4,

      },
      1199: {
        slidesPerView: 4,

      },


    }
  });
  var galleryTop4 = new Swiper('.gallery-top4', {
    spaceBetween: 0,
    touchRatio:0,

    touchStartPreventDefault:false,
    thumbs: {
      swiper: galleryThumbs4,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  galleryTop4.on('slideChange', function() {

    $('.swiper-slide').find('video').each(function() {
      this.pause();
    });

  });


  var galleryThumbs3 = new Swiper('.gallery-thumbs3', {
    spaceBetween: 10,
    slidesPerView: 4,

    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
   /* navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },*/
    breakpoints: {

      560: {
        slidesPerView: 2,

      },
      767: {
        slidesPerView: 3,

      },
      991: {
        slidesPerView: 4,

      },
      1199: {
        slidesPerView: 4,

      },


    }
  });

  $(".gallery-thumbs4 .swiper-button-next").click(function () {
    jQuery('.gallery-top4 .swiper-button-next').trigger('click');

  });
  $(".gallery-thumbs4 .swiper-button-prev").click(function () {
    jQuery('.gallery-top4 .swiper-button-prev').trigger('click');
  });
  $(".gallery-thumbs2 .swiper-button-next").click(function () {
    jQuery('.gallery-top2 .swiper-button-next').trigger('click');

  });
  $(".gallery-thumbs2 .swiper-button-prev").click(function () {
    jQuery('.gallery-top2 .swiper-button-prev').trigger('click');
  });
  $(".gallery-thumbs3 .swiper-button-next").click(function () {
    jQuery('.gallery-top3 .swiper-button-next').trigger('click');

  });
  $(".gallery-thumbs3 .swiper-button-prev").click(function () {
    jQuery('.gallery-top3 .swiper-button-prev').trigger('click');
  });
  var galleryTop3 = new Swiper('.gallery-top3', {
    spaceBetween: 0,
    touchRatio:0,

    touchStartPreventDefault:false,
    thumbs: {
      swiper: galleryThumbs3,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  galleryTop3.on('slideChange', function() {

    $('.swiper-slide').find('video').each(function() {
      this.pause();
    });

  });

  $('.services-detail__item-slw100').slick({
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  });


  var $aboutcl_slider = $('.about-clients')
  var aboutcl_slider_settings = {
    dots: true,
    arrows:false,
    infinite: true,
    autoplay: false,
    autoplaySpeed:2500,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 529,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      }
    ]
  };

// Init Slider
  $aboutcl_slider.slick(aboutcl_slider_settings);

// Reslick only if it's not slick()
  $(window).on('resize', function() {
    if (!$aboutcl_slider.hasClass('slick-initialized')) {
      return $aboutcl_slider.slick(aboutcl_slider_settings);
    }

  });

});

$(document).ready(function(){
  $("#logo").click(function (){
    $("body,html").animate({
      scrollTop:0}, 800);
    return false;
  });
});

